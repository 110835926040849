<modal #modal primaryBtnTextKey="Ok" [displayLaunchButton]="false" [isInformational]="true"
    (onCompleteAction)="resetModal()" [loadingIndicator]="false" [shortMode]="true" (primaryClick)="resetModal()">
    <div style="display: flex; justify-content: center;">
        @if(text)
        {
        {{text}}
        }
        @if(rows)
        {
        @for (row of rows; track $index) {
        {{row}}<br>
        }
        }
    </div>
</modal>