import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { LayoutComponent } from './layout/layout.component';
import { InformationModalComponent } from './shared/modal/information-modal/information-modal.component';
import { ModalService } from './core/service/modal.service';
import { ToastComponent } from "./shared/toast/toast.component";
import { ToastService } from './core/service/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [LayoutComponent, InformationModalComponent, ConfirmModalComponent, ToastComponent]
})
export class AppComponent implements AfterViewInit {
  @ViewChild('informationModal') informationModal: InformationModalComponent;
  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent;
  @ViewChild('toast') toast: ToastComponent;

  constructor(service: ModalService, private toastService: ToastService) {
    service.informationModal.subscribe(x => {
      this.informationModal.open(x);
      this.informationModal.close.subscribe(() => service.currentInfoText = '');
    });

    service.informationModalArray.subscribe(x => {
      this.informationModal.openRows(x);
      this.informationModal.close.subscribe(() => service.currentInfoTextArray = []);
    });

    service.confirmModal.subscribe(x => {
      this.confirmModal.openConfirm(x);
      service.onConfirm = this.confirmModal.complete;
    });
  }

  ngAfterViewInit(): void {
    this.toastService.setToastComponent(this.toast);
  }

  title = 'cms';
}
