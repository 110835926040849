<div [ngClass]="isDarkTheme ? 'dark-theme' : 'light-theme'">
    <div class="top">
        <div style="padding-bottom:0px; margin-bottom: 28px;" class="top-body bg-color">
            <div class="row">
                <div style="width: 70px; margin-right: 4px" class="col-1">
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="56" height="56" rx="28" fill="#00C896" />
                        <path
                            d="M32.2623 35.35C31.7373 35.35 31.3873 35.175 30.8623 35L28.7623 34.125L26.6623 35C26.3123 35.175 25.7873 35.35 25.2623 35.35C24.9123 35.35 24.7373 35.35 24.3873 35.175L23.5123 42L28.7623 38.5L34.0123 42L32.9623 35.175C32.7873 35.35 32.4373 35.35 32.2623 35.35ZM37.3373 24.325C37.1623 23.975 37.1623 23.45 37.3373 23.1L38.3873 21C38.7373 20.3 38.3873 19.425 37.5123 19.075L35.2373 18.2C34.8873 18.025 34.5373 17.675 34.3623 17.325L33.4873 15.05C33.3123 14.35 32.7873 14 32.2623 14C32.0873 14 31.7373 14 31.5623 14.175L29.2873 15.225H28.7623C28.5873 15.225 28.4123 15.225 28.2373 15.05L25.9623 14.175C25.7873 14 25.4373 14 25.2623 14C24.7373 14 24.2123 14.35 23.8623 14.875L22.9873 17.325C22.9873 17.675 22.6373 18.025 22.2873 18.2L19.8373 19.075C19.1373 19.25 18.7873 20.125 19.1373 21L20.1873 23.275C20.3623 23.625 20.3623 24.15 20.1873 24.5L19.1373 26.6C18.7873 27.3 19.1373 28.175 20.0123 28.525L22.2873 29.4C22.6373 29.575 22.9873 29.925 23.1623 30.275L24.0373 32.55C24.2123 33.25 24.7373 33.6 25.2623 33.6C25.4373 33.6 25.6123 33.6 25.7873 33.425L28.0623 32.375C28.2373 32.375 28.4123 32.2 28.5873 32.2C28.7623 32.2 28.9373 32.2 29.1123 32.375L31.3873 33.425C31.5623 33.6 31.7373 33.6 31.9123 33.6C32.4373 33.6 32.9623 33.25 33.3123 32.725L34.1873 30.45C34.3623 30.1 34.7123 29.75 35.0623 29.575L37.3373 28.7C38.0373 28.35 38.5623 27.475 38.2123 26.775L37.3373 24.325ZM28.7623 30.8C24.9123 30.8 21.7623 27.65 21.7623 23.8C21.7623 19.95 24.9123 16.8 28.7623 16.8C32.6123 16.8 35.7623 19.95 35.7623 23.8C35.7623 27.65 32.6123 30.8 28.7623 30.8Z"
                            fill="white" />
                        <path
                            d="M34.0122 23.8C34.0122 25.1924 33.4591 26.5277 32.4745 27.5123C31.49 28.4969 30.1546 29.05 28.7622 29.05C27.3698 29.05 26.0345 28.4969 25.0499 27.5123C24.0653 26.5277 23.5122 25.1924 23.5122 23.8C23.5122 22.4076 24.0653 21.0722 25.0499 20.0877C26.0345 19.1031 27.3698 18.55 28.7622 18.55C30.1546 18.55 31.49 19.1031 32.4745 20.0877C33.4591 21.0722 34.0122 22.4076 34.0122 23.8Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="col">
                    <div class="row top-title-text title-color">Last month top 3</div>
                </div>
            </div>
            @if (top3UsersDataByYearTopSales && top3UsersDataByYearTopSales.length > 0) {
            <div class="top-frame top-frame-full  bg-color">
                @for(displayOrder of top3UsersDisplayOrder; track top3Identify($index, displayOrder)) {
                <div class="top-column">
                    <div class="top-block">
                        @if(top3UsersDataByYearTopSales[displayOrder].avatarUrl) {
                        <div><img [src]="top3UsersDataByYearTopSales[displayOrder].avatarUrl" class="avatar" /></div>
                        }
                        @else {
                        <div class="avatar">{{ displayOrder + 1 }}</div>
                        }
                        @if(top3UsersDataByYearTopSales[displayOrder].name) {
                        <div class="top-text">
                            <div class="row">{{top3UsersDataByYearTopSales[displayOrder].name.split(' ')[0]}}</div>
                            <div class="row">{{top3UsersDataByYearTopSales[displayOrder].name.split(' ')[1]}}</div>
                        </div>
                        }
                        @if(top3UsersDataByYearTopSales[displayOrder].sales) {
                        <div class="top-text">
                            <div class="amount">${{textFormater(top3UsersDataByYearTopSales[displayOrder].sales,false)}}
                            </div>
                        </div>
                        }
                    </div>
                </div>
                }
            </div>
            }
        </div>
        <div class="two-chart-frame bg-color">
            <div class="chart-frame" style="margin-right: 10px;">
                <div class="row title">
                    <div style="width: 70px; margin-right: 4px" class="col-1">
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="56" height="56" rx="28" fill="#00C896" />
                            <path
                                d="M32.2623 35.35C31.7373 35.35 31.3873 35.175 30.8623 35L28.7623 34.125L26.6623 35C26.3123 35.175 25.7873 35.35 25.2623 35.35C24.9123 35.35 24.7373 35.35 24.3873 35.175L23.5123 42L28.7623 38.5L34.0123 42L32.9623 35.175C32.7873 35.35 32.4373 35.35 32.2623 35.35ZM37.3373 24.325C37.1623 23.975 37.1623 23.45 37.3373 23.1L38.3873 21C38.7373 20.3 38.3873 19.425 37.5123 19.075L35.2373 18.2C34.8873 18.025 34.5373 17.675 34.3623 17.325L33.4873 15.05C33.3123 14.35 32.7873 14 32.2623 14C32.0873 14 31.7373 14 31.5623 14.175L29.2873 15.225H28.7623C28.5873 15.225 28.4123 15.225 28.2373 15.05L25.9623 14.175C25.7873 14 25.4373 14 25.2623 14C24.7373 14 24.2123 14.35 23.8623 14.875L22.9873 17.325C22.9873 17.675 22.6373 18.025 22.2873 18.2L19.8373 19.075C19.1373 19.25 18.7873 20.125 19.1373 21L20.1873 23.275C20.3623 23.625 20.3623 24.15 20.1873 24.5L19.1373 26.6C18.7873 27.3 19.1373 28.175 20.0123 28.525L22.2873 29.4C22.6373 29.575 22.9873 29.925 23.1623 30.275L24.0373 32.55C24.2123 33.25 24.7373 33.6 25.2623 33.6C25.4373 33.6 25.6123 33.6 25.7873 33.425L28.0623 32.375C28.2373 32.375 28.4123 32.2 28.5873 32.2C28.7623 32.2 28.9373 32.2 29.1123 32.375L31.3873 33.425C31.5623 33.6 31.7373 33.6 31.9123 33.6C32.4373 33.6 32.9623 33.25 33.3123 32.725L34.1873 30.45C34.3623 30.1 34.7123 29.75 35.0623 29.575L37.3373 28.7C38.0373 28.35 38.5623 27.475 38.2123 26.775L37.3373 24.325ZM28.7623 30.8C24.9123 30.8 21.7623 27.65 21.7623 23.8C21.7623 19.95 24.9123 16.8 28.7623 16.8C32.6123 16.8 35.7623 19.95 35.7623 23.8C35.7623 27.65 32.6123 30.8 28.7623 30.8Z"
                                fill="white" />
                            <path
                                d="M34.0122 23.8C34.0122 25.1924 33.4591 26.5277 32.4745 27.5123C31.49 28.4969 30.1546 29.05 28.7622 29.05C27.3698 29.05 26.0345 28.4969 25.0499 27.5123C24.0653 26.5277 23.5122 25.1924 23.5122 23.8C23.5122 22.4076 24.0653 21.0722 25.0499 20.0877C26.0345 19.1031 27.3698 18.55 28.7622 18.55C30.1546 18.55 31.49 19.1031 32.4745 20.0877C33.4591 21.0722 34.0122 22.4076 34.0122 23.8Z"
                                fill="white" />
                        </svg>
                    </div>
                    <div class="col">
                        <div class="row top-title-text title-color">Top 3 leadership pay for last year in CAD</div>
                    </div>
                </div>
                @if (top3UsersDataByYearLeadershipPay && top3UsersDataByYearLeadershipPay.length > 0) {
                <div class="top-frame top-frame-short ">
                    @for(displayOrder of top3UsersDisplayOrder; track top3Identify($index, displayOrder)) {
                    <div class="top-column">
                        <div class="top-block">
                            @if(top3UsersDataByYearLeadershipPay[displayOrder].avatarUrl) {
                            <div><img [src]="top3UsersDataByYearLeadershipPay[displayOrder].avatarUrl" class="avatar" />
                            </div>
                            }
                            @else {
                            <div class="avatar">{{ displayOrder + 1 }}</div>
                            }
                            @if(top3UsersDataByYearLeadershipPay[displayOrder].name) {
                            <div class="top-text">
                                <div class="row">{{top3UsersDataByYearLeadershipPay[displayOrder].name.split(' ')[0]}}
                                </div>
                                <div class="row">{{top3UsersDataByYearLeadershipPay[displayOrder].name.split(' ')[1]}}
                                </div>
                            </div>
                            }
                            @if(top3UsersDataByYearLeadershipPay[displayOrder].sales) {
                            <div class="top-text">
                                <div class="amount">
                                    ${{textFormater(top3UsersDataByYearLeadershipPay[displayOrder].sales,true)}}</div>
                            </div>
                            }
                        </div>
                    </div>
                    }
                </div>
                }
            </div>
            <div class="chart-frame">
                <div class="row title">
                    <div style="width: 70px; margin-right: 4px" class="col-1">
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="56" height="56" rx="28" fill="#00C896" />
                            <path
                                d="M32.2623 35.35C31.7373 35.35 31.3873 35.175 30.8623 35L28.7623 34.125L26.6623 35C26.3123 35.175 25.7873 35.35 25.2623 35.35C24.9123 35.35 24.7373 35.35 24.3873 35.175L23.5123 42L28.7623 38.5L34.0123 42L32.9623 35.175C32.7873 35.35 32.4373 35.35 32.2623 35.35ZM37.3373 24.325C37.1623 23.975 37.1623 23.45 37.3373 23.1L38.3873 21C38.7373 20.3 38.3873 19.425 37.5123 19.075L35.2373 18.2C34.8873 18.025 34.5373 17.675 34.3623 17.325L33.4873 15.05C33.3123 14.35 32.7873 14 32.2623 14C32.0873 14 31.7373 14 31.5623 14.175L29.2873 15.225H28.7623C28.5873 15.225 28.4123 15.225 28.2373 15.05L25.9623 14.175C25.7873 14 25.4373 14 25.2623 14C24.7373 14 24.2123 14.35 23.8623 14.875L22.9873 17.325C22.9873 17.675 22.6373 18.025 22.2873 18.2L19.8373 19.075C19.1373 19.25 18.7873 20.125 19.1373 21L20.1873 23.275C20.3623 23.625 20.3623 24.15 20.1873 24.5L19.1373 26.6C18.7873 27.3 19.1373 28.175 20.0123 28.525L22.2873 29.4C22.6373 29.575 22.9873 29.925 23.1623 30.275L24.0373 32.55C24.2123 33.25 24.7373 33.6 25.2623 33.6C25.4373 33.6 25.6123 33.6 25.7873 33.425L28.0623 32.375C28.2373 32.375 28.4123 32.2 28.5873 32.2C28.7623 32.2 28.9373 32.2 29.1123 32.375L31.3873 33.425C31.5623 33.6 31.7373 33.6 31.9123 33.6C32.4373 33.6 32.9623 33.25 33.3123 32.725L34.1873 30.45C34.3623 30.1 34.7123 29.75 35.0623 29.575L37.3373 28.7C38.0373 28.35 38.5623 27.475 38.2123 26.775L37.3373 24.325ZM28.7623 30.8C24.9123 30.8 21.7623 27.65 21.7623 23.8C21.7623 19.95 24.9123 16.8 28.7623 16.8C32.6123 16.8 35.7623 19.95 35.7623 23.8C35.7623 27.65 32.6123 30.8 28.7623 30.8Z"
                                fill="white" />
                            <path
                                d="M34.0122 23.8C34.0122 25.1924 33.4591 26.5277 32.4745 27.5123C31.49 28.4969 30.1546 29.05 28.7622 29.05C27.3698 29.05 26.0345 28.4969 25.0499 27.5123C24.0653 26.5277 23.5122 25.1924 23.5122 23.8C23.5122 22.4076 24.0653 21.0722 25.0499 20.0877C26.0345 19.1031 27.3698 18.55 28.7622 18.55C30.1546 18.55 31.49 19.1031 32.4745 20.0877C33.4591 21.0722 34.0122 22.4076 34.0122 23.8Z"
                                fill="white" />
                        </svg>
                    </div>
                    <div class="col">
                        <div class="row top-title-text title-color">Top 3 clothiers in 2024 (total yearly amount sold)
                        </div>
                    </div>
                </div>
                @if (top3UsersDataByYearBiggestSale && top3UsersDataByYearBiggestSale.length > 0) {
                <div class="top-frame top-frame-short ">
                    @for(displayOrder of top3UsersDisplayOrder; track top3Identify($index, displayOrder)) {
                    <div class="top-column">
                        <div class="top-block">
                            @if(top3UsersDataByYearBiggestSale[displayOrder].avatarUrl) {
                            <div><img [src]="top3UsersDataByYearBiggestSale[displayOrder].avatarUrl" class="avatar" />
                            </div>
                            }
                            @else {
                            <div class="avatar">{{ displayOrder + 1 }}</div>
                            }
                            @if(top3UsersDataByYearBiggestSale[displayOrder].name) {
                            <div class="top-text">
                                <div class="row">{{top3UsersDataByYearBiggestSale[displayOrder].name.split(' ')[0]}}
                                </div>
                                <div class="row">{{top3UsersDataByYearBiggestSale[displayOrder].name.split(' ')[1]}}
                                </div>
                            </div>
                            }
                            @if(top3UsersDataByYearBiggestSale[displayOrder].sales) {
                            <div class="top-text">
                                <div class="amount">
                                    ${{textFormater(top3UsersDataByYearBiggestSale[displayOrder].sales,false)}}</div>
                            </div>
                            }
                        </div>
                    </div>
                    }
                </div>
                }
            </div>
        </div>
        <div style="padding-bottom:0px; margin-top: 28px;" class="top-body bg-color">
            <div class="row">
                <div style="width: 70px; margin-right: 4px" class="col-1">
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="56" height="56" rx="28" fill="#00C896" />
                        <path
                            d="M32.2623 35.35C31.7373 35.35 31.3873 35.175 30.8623 35L28.7623 34.125L26.6623 35C26.3123 35.175 25.7873 35.35 25.2623 35.35C24.9123 35.35 24.7373 35.35 24.3873 35.175L23.5123 42L28.7623 38.5L34.0123 42L32.9623 35.175C32.7873 35.35 32.4373 35.35 32.2623 35.35ZM37.3373 24.325C37.1623 23.975 37.1623 23.45 37.3373 23.1L38.3873 21C38.7373 20.3 38.3873 19.425 37.5123 19.075L35.2373 18.2C34.8873 18.025 34.5373 17.675 34.3623 17.325L33.4873 15.05C33.3123 14.35 32.7873 14 32.2623 14C32.0873 14 31.7373 14 31.5623 14.175L29.2873 15.225H28.7623C28.5873 15.225 28.4123 15.225 28.2373 15.05L25.9623 14.175C25.7873 14 25.4373 14 25.2623 14C24.7373 14 24.2123 14.35 23.8623 14.875L22.9873 17.325C22.9873 17.675 22.6373 18.025 22.2873 18.2L19.8373 19.075C19.1373 19.25 18.7873 20.125 19.1373 21L20.1873 23.275C20.3623 23.625 20.3623 24.15 20.1873 24.5L19.1373 26.6C18.7873 27.3 19.1373 28.175 20.0123 28.525L22.2873 29.4C22.6373 29.575 22.9873 29.925 23.1623 30.275L24.0373 32.55C24.2123 33.25 24.7373 33.6 25.2623 33.6C25.4373 33.6 25.6123 33.6 25.7873 33.425L28.0623 32.375C28.2373 32.375 28.4123 32.2 28.5873 32.2C28.7623 32.2 28.9373 32.2 29.1123 32.375L31.3873 33.425C31.5623 33.6 31.7373 33.6 31.9123 33.6C32.4373 33.6 32.9623 33.25 33.3123 32.725L34.1873 30.45C34.3623 30.1 34.7123 29.75 35.0623 29.575L37.3373 28.7C38.0373 28.35 38.5623 27.475 38.2123 26.775L37.3373 24.325ZM28.7623 30.8C24.9123 30.8 21.7623 27.65 21.7623 23.8C21.7623 19.95 24.9123 16.8 28.7623 16.8C32.6123 16.8 35.7623 19.95 35.7623 23.8C35.7623 27.65 32.6123 30.8 28.7623 30.8Z"
                            fill="white" />
                        <path
                            d="M34.0122 23.8C34.0122 25.1924 33.4591 26.5277 32.4745 27.5123C31.49 28.4969 30.1546 29.05 28.7622 29.05C27.3698 29.05 26.0345 28.4969 25.0499 27.5123C24.0653 26.5277 23.5122 25.1924 23.5122 23.8C23.5122 22.4076 24.0653 21.0722 25.0499 20.0877C26.0345 19.1031 27.3698 18.55 28.7622 18.55C30.1546 18.55 31.49 19.1031 32.4745 20.0877C33.4591 21.0722 34.0122 22.4076 34.0122 23.8Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="col">
                    <div class="row top-title-text title-color">Top 3 market leader bonus for last year</div>
                </div>
            </div>
            @if (top3UsersDataByYearMarketLeader && top3UsersDataByYearMarketLeader.length > 0) {
            <div class="top-frame top-frame-full  bg-color">
                @for(displayOrder of top3UsersDisplayOrder; track top3Identify($index, displayOrder)) {
                <div class="top-column">
                    <div class="top-block">
                        @if(top3UsersDataByYearMarketLeader[displayOrder].avatarUrl) {
                        <div><img [src]="top3UsersDataByYearMarketLeader[displayOrder].avatarUrl" class="avatar" />
                        </div>
                        }
                        @else {
                        <div class="avatar">{{ displayOrder + 1 }}</div>
                        }
                        @if(top3UsersDataByYearMarketLeader[displayOrder].name) {
                        <div class="top-text">
                            <div class="row">{{top3UsersDataByYearMarketLeader[displayOrder].name.split(' ')[0]}}</div>
                            <div class="row">{{top3UsersDataByYearMarketLeader[displayOrder].name.split(' ')[1]}}</div>
                        </div>
                        }
                        @if(top3UsersDataByYearMarketLeader[displayOrder].sales) {
                        <div class="top-text">
                            <div class="amount">
                                ${{textFormater(top3UsersDataByYearMarketLeader[displayOrder].sales,true)}}
                            </div>
                        </div>
                        }
                    </div>
                </div>
                }
            </div>
            }
        </div>
    </div>
</div>