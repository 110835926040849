<div class="start-page" [ngClass]="isDarkTheme ? 'dark-theme' : 'light-theme'">
  <div class="star-page-container">
    <div id="hellow-text-top" class="title-color">Welcome back, {{ repName }}!</div>
    <div id="hellow-text-bot" class="text-color">
      We’re on the way to 100 Million By 2026
    </div>
    <progress-bar-panel></progress-bar-panel>
    <top-3-users></top-3-users>
    @defer (on immediate) {
    @if(mobile) {
    <div>
      <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="false">
        <div (click)="pauseAllVideos()" class="carousel-indicators">
          <button #firstCarouselItem type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
            class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
            aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
            aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"
            aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4"
            aria-label="Slide 5"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5"
            aria-label="Slide 6"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6"
            aria-label="Slide 7"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7"
            aria-label="Slide 8"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="8"
            aria-label="Slide 9"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="9"
            aria-label="Slide 10"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="10"
            aria-label="Slide 11"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="11"
            aria-label="Slide 12"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="12"
            aria-label="Slide 13"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="13"
            aria-label="Slide 14"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="14"
            aria-label="Slide 15"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="15"
            aria-label="Slide 16"></button>
        </div>
        <div class="carousel-inner">
          <div class="video-carusel">
            @for(video of videos; track videoIdentify($index, video))
            {
            <div class="carousel-item" [ngClass]="{'active' : video.isActive}">
              @if(video.isVideoLoaded) {
              <div class="video-box-1">
                <video #element (click)="onVideoClick(element)" (ended)="onVideoEnded(video.id)" [poster]="video.poster"
                  playsinline autoplay>
                  <source [src]="video.source" [type]="sourceType" />
                </video>
              </div>
              }
              @else {
              <div class="video-box-1"><img (click)="onPosterClick(video.id)" [src]="video.poster" /></div>
              }
            </div>
            }
          </div>
        </div>
        <button class="carousel-control-prev" (click)="pauseAllVideos()" type="button"
          data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" (click)="pauseAllVideos()" type="button"
          data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    }@else {
    <div class="video-wrapper">
      <div class="video-wrap">
        @for(video of videos; track videoIdentify($index, video))
        {
        @if(video.isVideoLoaded)
        {
        <div class="video-box-1">
          <video #element (click)="onVideoClick(element)" (ended)="onVideoEnded(video.id)" [poster]="video.poster"
            autoplay>
            <source [src]="video.source" [type]="sourceType" />
          </video>
        </div>
        }
        @else {
        <div class="video-box-1"><img (click)="onPosterClick(video.id)" [src]="video.poster" /></div>
        }
        }
      </div>
    </div>
    }
    } @placeholder {
    Videos loading...
    }
    <div class="chart-body">
      <bar-chart icon="assets/start-page/chart-title-icon.png" chartTitle="Sales - current month, CAD"
        [additionalTitle]="'Current Month: $' +chartMonthlySum" [labels]="chartMonthlyLabels" [data]="chartMonthlyData"
        [horizontal]="true" [stepSize]="stepMonthlySize" [height]="chartMonthlyHeight" [maintainAspectRatio]="false"
        [maxScale]="maxMonthlyScale + 1000" [showDatalabels]="false" [width]="650"></bar-chart>
    </div>
    <div class="chart-body">
      <bar-chart icon="assets/start-page/chart-title-icon.png" chartTitle="Sales - top 5 current year, CAD"
        [labels]="chartTop5Labels" [stepSize]="250000" [data]="chartTop5Data" [horizontal]="true"
        [height]="chartTop5Height" [maintainAspectRatio]="false" [maxScale]="maxTop5Scale" [showDatalabels]="false"
        [width]="650"></bar-chart>
    </div>
  </div>
</div>