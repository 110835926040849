{
  "ClientOverdue": " Warning! 'Save Order' button is disabled. Current client is in overdue report(refresh thepage when you're done).",
  "UpdateMeasurements": "Warning! 'Upload', 'Deposit' and 'Cashout' buttons are disabled. You must update the measurements of your client in Edit client info and click on Save.",
  "RequiredClientInfo": "Warning! 'Upload', 'Deposit' and 'Cashout' buttons are disabled. You must fill in all required information about your client in Client info tab and click Save there and get back to this tab. Information missed:",
  "AssignedUserInactive": "Assigned user is inactive please assign client to another user",
  "SubmitPayment": {
    "firstName": "First Name",
    "lastName": "Last Name",
    "gender": "Gender",
    "company": "Company",
    "address": "Address",
    "city": "City",
    "linkedinLocation": "Linkedin Location",
    "linkedInURL": "LinkedIn URL",
    "provinceId": "Province",
    "zipcode": "ZIP/Postal Code",
    "country": "Country/Region",
    "height": "Height",
    "chest": "Chest",
    "jacketWaist": "Jacket Waist (body/HG)",
    "backWidth": "Back Width (G/body)",
    "sleeveJacket": "Sleeve Jacket",
    "trouserWaist": "Trouser Waist",
    "outsideLeg": "Outside Leg",
    "neck": "Neck",
    "frontFileId": "Front Photo",
    "sideFileId": "Side Photo",
    "sideFile2Id": "Side Photo 2",
    "backFileId": "Back Photo"
  },
  "Error": {
    "OrderError1": "Order total less then 0 exception",
    "RefundError": "Refund money to client in payment system fail",
    "TokenError": "Token generation error",
    "PaymentError": "Payment system error",
    "UserExist": "User already exist",
    "RefundAmountGreaterThanOrderTotal": "Refund amount cannot be greater than order total. May be refund already done",
    "RefundAmountGreaterThanOrderDeposit": "Refund amount cannot be greater than order deposit. May be refund already done",
    "AvailableRefundAmountLessThanZero": "Available refund amount less or equal than zero",
    "RefundAmountLessThanZero": "Refund amount less or equal than zero",
    "CertificateExists": "Certificate already exists",
    "CertificateReferenceDetected": "Delete canceled! Certificate already attached to some order",
    "ProductInPackages": "Product include in package(s). Remove product from package(s) first",
    "ProvinceAlreadyExists": "Province already exists",
    "OfficeAlreadyExists": "Office already exists",
    "OrderNotExists": "Order not exists",
    "DuplicateDiscount": "Duplicate discount record"
  },
  "Validation": {
    "required": "Field is required",
    "positive": "Should be positive numeric value",
    "generic": "There are validation errors on the form",
    "email": "Email not valid",
    "phone": "Phone not valid",
    "nonWhitespace": "Value should not be whitespace",
    "rangeLength": "The field should be between {{left}} - {{right}} characters",
    "minlength": "The field should contain at least {{requiredLength}} characters",
    "min": "Should be greater then {{requiredValue}}",
    "fileInvalid": "File is invalid {{message}}",
    "cvv": "CVV is invalid",
    "expiry": "Expiry date is invalid"
  },
  "Enums": {
    "OrderStatus": {
      "1": "Saved",
      "2": "Uploaded",
      "3": "CashedOut"
    },
    "ItemStatus": {
      "1": "Submitted",
      "2": "Approved",
      "3": "Rejected"
    },
    "AlterationType": {
      "1": "Credit",
      "2": "Local",
      "3": "UK",
      "4": "Remake",
      "5": "Refund",
      "6": "Write Off",
      "7": "Returned Write Off"
    },
    "ExpenseType": {
      "1": "Non ENCP",
      "2": "Rep Paid",
      "3": "Company Paid",
      "4": "Refund"
    },
    "DiscountType": {
      "1": "Late order",
      "2": "Repo",
      "3": "EA",
      "4": "SM/BB",
      "5": "Order shipped",
      "6": "None"
    },
    "TimePeriod": {
      "1": "Period 1",
      "2": "Period 2"
    },
    "Gender": {
      "1": "Male",
      "2": "Female"
    },
    "ClientRating": {
      "1": "A",
      "2": "B",
      "3": "C",
      "4": "D"
    },
    "PackageType": {
      "1": "Platinum",
      "2": "Business",
      "3": "Casual"
    },
    "UserRoleEnum": {
      "1": "Admin",
      "2": "SM",
      "3": "Rep",
      "4": "EA",
      "5": "Expense Approver",
      "6": "Expense Submitter",
      "7": "Alteration Approver",
      "9": "Accounting",
      "10": "CFO",
      "11": "Tech Support",
      "12": "Market Leader",
      "13": "BB"
    }
  }
}