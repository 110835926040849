import { AfterViewInit, Component, OnInit, } from "@angular/core";
import { CommonModule } from '@angular/common';
import { TopSalesModel } from "src/app/core/models/charts/top-sales.model";
import { UserContextService } from "src/app/core/service/user-context.service";
import { ChartService } from "src/app/core/service/chart.service";

@Component({
  selector: 'top-3-users',
  templateUrl: './top-3-users.component.html',
  styleUrls: ['./top-3-users.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class Top3UsersComponent implements OnInit, AfterViewInit {
  public isDarkTheme: boolean;
  public top3UsersDataByYearTopSales: TopSalesModel[];
  public top3UsersDataByYearBiggestSale: TopSalesModel[];
  public top3UsersDataByYearLeadershipPay: TopSalesModel[];
  public top3UsersDataByYearMarketLeader: TopSalesModel[];
  public top3UsersDisplayOrder = [1, 0, 2];
  public constructor(
    private userContextService: UserContextService,
    private chartService: ChartService) {
    this.top3UsersDataByYearTopSales = [
      new TopSalesModel(),
      new TopSalesModel(),
      new TopSalesModel()
    ];
    this.top3UsersDataByYearBiggestSale = [
      new TopSalesModel(),
      new TopSalesModel(),
      new TopSalesModel()
    ];
    this.top3UsersDataByYearLeadershipPay = [
      new TopSalesModel(),
      new TopSalesModel(),
      new TopSalesModel()
    ];
    this.top3UsersDataByYearMarketLeader = [
      new TopSalesModel(),
      new TopSalesModel(),
      new TopSalesModel()
    ];
  }

  public ngAfterViewInit(): void {
    this.userContextService.themeState.subscribe(x => {
      this.isDarkTheme = x;
    });
  }

  public async ngOnInit(): Promise<void> {
    this.top3UsersDataByYearTopSales = await this.chartService.getTop3Users();
    this.top3UsersDataByYearBiggestSale = await this.chartService.getTop3BiggestUserSalesByLastYear();
    this.top3UsersDataByYearLeadershipPay = await this.chartService.getTop3UsersLeadershipPay();
    this.top3UsersDataByYearMarketLeader = await this.chartService.getTop3UsersMarketLeader();
  }

  public textFormater(value: number, asDouble: boolean): string {
    const result = value / 1000;
    if (result < 1) {
      return value.toFixed(2);
    }
    if (result < 1000) {
      return asDouble ? (result.toFixed(2) + 'k').replace('.00', '') : result.toFixed() + 'k';
    }
    return ((result / 1000).toFixed(2) + 'm').replace('.00', '');
  }

  public top3Identify(index, item) {
    return item;
  }
}
